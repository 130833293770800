import e from "cors";
import React, { useEffect, useState } from "react";
import "./TradingMarket.scss";
import moment from "moment";

export const RecentTransaction = ({
  candlestickPayload,
  betRecordsListResult,
  typeMoney,
}) => {
  const [prevSticks, setPrevSticks] = useState(candlestickPayload || []);

  useEffect(() => {
    if (betRecordsListResult.length === 0) return;

    let newItem =
      betRecordsListResult[
        betRecordsListResult.length - 1
      ].gameRecordUnit.toLowerCase() !== (typeMoney + "-usd").toLowerCase()
        ? undefined
        : betRecordsListResult[betRecordsListResult.length - 1];

    if (newItem) {
      let _newList = [newItem, ...prevSticks];
      let __shouldUseIndex = _newList.length - 1;
      // console.log(`_newList[0].gameRecordSection * 1: ${_newList[0].gameRecordSection * 1}`)
      // console.log(` _newList[1].gameRecordSection* 1 : ${ _newList[1].gameRecordSection* 1 }`)
      if (_newList.length >= 2 && _newList[0].gameRecordSection * 1 - _newList[1].gameRecordSection* 1 > 1) {
        newItem.gameRecordSection = _newList[0].gameRecordSection * 1 - 1;
      }

      setPrevSticks([...prevSticks, newItem]);
    }
  }, [candlestickPayload?.length, betRecordsListResult?.length]);

  return (
    <div className="left-list-container">
      <div>
        <div className="top">
          <div className="top-description d-flex">
            <span style={{ width: "45%" }}>Kỳ</span>
            <span style={{ width: "30%" }}>Tổng</span>
            <span style={{ width: "25%" }}>Kết quả</span>
          </div>

          <div className={`price-list`}>
            {prevSticks &&
              prevSticks.map((item, i) => {
                // const date = new Date();
                // date.setFullYear(item.gameRecordSection.toString().slice(0, 4));
                // date.setMonth(
                //   Number(item.gameRecordSection.toString().slice(4, 6) + 1)
                // );
                // date.setDate(item.gameRecordSection.toString().slice(6, 8));
                // date.setHours(item.gameRecordSection.toString().slice(8, 10));
                // date.setMinutes(
                //   item.gameRecordSection.toString().slice(10, 12)
                // );
                return (
                  <div
                    key={`recenttransaction-${item.gameRecordUnit}-${item.gameRecordPrice}-${item.gameRecordSection}${i}`}
                    className="price-list-item d-flex"
                  >
                    <span
                      className={`${
                        item.gameRecordTypeDown === 1 ? "red" : "green"
                      }`}
                      style={{ width: "45%" }}
                    >
                      {item.gameRecordSection}
                    </span>
                    <span
                      className={`${
                        item.gameRecordTypeDown === 1 ? "red" : "green"
                      }`}
                      style={{ width: "35%" }}
                    >
                      {`${item.gameRecordPrice}`}
                    </span>
                    <span
                      className={`${
                        item.gameRecordTypeDown === 1 ? "red" : "green"
                      }`}
                      style={{ width: "20%" }}
                    >
                      {item.gameRecordTypeDown ? "Xuống" : "Lên"}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
