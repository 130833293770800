import { number_to_price } from "helper/common";
import moment from "moment";
import React from "react";
import { RecentTransaction } from "./RecentTransaction";

export const RightMenu = ({
  betRecordsListLive,
  handleRenderTextType,
  candlestickPayload,
  typeMoney,
  betRecordsListResult,
}) => {
  return (
    <div className="right-content">
      <span className="frame-title active">Giao dịch trực tiếp</span>
      {betRecordsListLive && (
        <div className="left-list-container">
          <div className="px-2">
            <div className="top">
              <div className="top-description d-flex">
                <span>Thời gian</span>
                <span>Cặp</span>
                <span>Tổng</span>
              </div>

              <div className={`price-list`}>
                {betRecordsListLive.map((item) => (
                  <div
                    // key={`tradingmarket-${item.betRecordUnit}-${
                    //   item.betRecordAmountIn
                    // }-${item.updatedAt || item.createdAt}`}
                    className="price-list-item d-flex"
                  >
                    <span
                      className={`${
                        item.betRecordType === "BetDown" ? "red" : "green"
                      }`}
                    >
                      {moment(item.updatedAt || item.createdAt).format(
                        "HH:mm:ss"
                      )}
                    </span>
                    <span
                      className={`${
                        item.betRecordType === "BetDown" ? "red" : "green"
                      }`}
                    >
                      {item.betRecordUnit}
                    </span>
                    <span
                      className={`${
                        item.betRecordType === "BetDown" ? "red" : "green"
                      }`}
                    >
                      {number_to_price(item.betRecordAmountIn)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <br />

      {/* <>
        <span className="frame-title active">
          Kết quả phiên giao dịch vừa qua
        </span>
        <RecentTransaction
          handleRenderTextType={handleRenderTextType}
          candlestickPayload={candlestickPayload}
          betRecordsListResult={betRecordsListResult}
          typeMoney={typeMoney}
        />
      </> */}
    </div>
  );
};
